import React, { useEffect, useContext, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  MenuItem,
} from "@material-ui/core";
import useStyles from "../Login/styles";
import { TextField } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { CartContext } from "../../Context/CartContext";
import Backdrop from "@material-ui/core/Backdrop";
import Loader from "../Loader/Loader";
import axios from "axios";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

export const BusinessAccountDetails = () => {
  const classes = useStyles();
  const history = useHistory();
  const { baseURL, loginDetailsBag, dataReceiver } = useContext(CartContext);
  const [loginDetails, setLoginDetails] = loginDetailsBag;
  const [open, setOpen] = useState(false);
  const [paymentPlatforms, setPaymentPlatforms] = React.useState([]);
  const [responseStatus, setResponseStatus] = React.useState([]);
  const [selectedPaymentPlatform, setSelectedPaymentPlatform] = React.useState(
    []
  );
  const [bankCharges, setBankCharges] = React.useState([]);
  const [bankChargeAmount, setBankChargeAmount] = React.useState([]);
  const [isWithCharges, setIsWithCharges] = React.useState(true);
  const [currentOutboundBroadcastBalance, setCurrentOutboundBroadcastBalance] =
    useState("");
  const [payee, setPayee] = useState("");
  let selectedBankChargeAmount = "";
  let selectedPlatform = "";
  let data2 = {};
  let data = {};
  let bankTransactionDetailsBag = {};
  const dataDec = dataReceiver;
  const senderDetailsBag = history.location.bankerDetailsState;

  useEffect(() => {
    setOpen(!open);
    const fetchPaymentPlatform = async () => {
      const res = await axios.get(baseURL + "bridgeGetMobileMoneyVendors.php");

      setOpen(false);
    };

    const fetchBankCharges = async () => {
      let url = baseURL;

      const res = await axios.get(
        url + "bridgeGetPaymentGatewaySwitchingCharges.php"
      );
    };

    setOpen(false);
  }, []);

  const outBoundLegalAvailableBalance = () => {
    setOpen(!open);
    let data = { comapnyID: loginDetails.id };
    data = JSON.stringify(data);

    const resp = axios
      .post(baseURL + "bridgeGetLegalCompanyOutboundBroadcastBalance.php", data)
      .then(function (response) {
        response.data = dataDec(response.data);
        setCurrentOutboundBroadcastBalance(response.data);

        setOpen(false);
      });
  };

  const handleBankChargesChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    var selectedBankChargeAmount = "";
    let amount = "";
    setIsWithCharges(!isWithCharges);

    for (let i = 0; i < bankCharges.length; i++) {
      let startRange = parseFloat(bankCharges[i].startRange);
      let endRange = parseFloat(bankCharges[i].endRange);
      let vendor = parseFloat(bankCharges[i].localEftVendors_id);

      if (startRange <= amount && amount < endRange) {
        if (vendor == 1) {
          let charge = bankCharges[i].chargeAmount;
          selectedBankChargeAmount = charge;
        } else if (vendor != 1) {
        }
      } else {
      }
    }

    let transferAmount = "";
    let currentChargeInclusion = isWithCharges;

    if (currentChargeInclusion == true) {
      let totalBalance = (
        parseFloat(amount) + parseFloat(selectedBankChargeAmount)
      ).toFixed(2);
      setBankChargeAmount(totalBalance);
    } else {
      setBankChargeAmount(amount);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedPaymentPlatform(event.target.value);
    selectedPlatform = event.target.value;
  };

  const submitBulkPayment = () => {
    setOpen(!open);
    let amount = senderDetailsBag.amount;
    let reference = senderDetailsBag.reference;
    let bank = senderDetailsBag.bank;
    let bankID = senderDetailsBag.bankID;
    let teamMemberID = senderDetailsBag.teamMemberID;

    let data = {
      amount: amount,
      reference: reference,
      bank: bank,
      bankID: bankID,
      teamMemberID: teamMemberID,
      companyID: loginDetails.organisations_id,
    };

    // return 0;

    data = JSON.stringify(data);

    const res = axios
      .post(baseURL + "bridgeReceiveUnitRechargeRequest.php", data)
      .then(function (response) {
        response.data = dataDec(response.data);

        if (response.data !== "") {
          let responseStatus = response.data;
          goToStatus(responseStatus);
          setOpen(false);
        } else {
          alert(response.data.description);
          setOpen(false);
        }
      });
  };

  const goToStatus = (responseStatus) =>
    history.push({
      pathname: "/businessactivityresponse",
      responseState: responseStatus,
    });

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <Grid container direction="column">
        <Grid item></Grid>

        <Grid item container spacing={4}>
          {/*<Grid item xs={false} sm={4}/>
            
                        <Grid item xs={12} sm={4}>*/}
          <Grid item xs={false} sm={2} md={3} lg={4} />
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <Card>
              <Backdrop className={classes.backdrop} open={open}>
                <Loader />
              </Backdrop>

              <CardContent>
                <Typography align="center" variant="body2" gutterBottom>
                  <h5>Confirm Details</h5>
                </Typography>

                <Typography variant="body1" gutterBottom>
                  Bank: <b>{senderDetailsBag.bank}</b>
                </Typography>

                <Typography variant="body1" gutterBottom>
                  Account #: <b>{senderDetailsBag.bankAccount}</b>
                </Typography>

                <Typography variant="body1" gutterBottom>
                  Loaded Balance: <b>E{senderDetailsBag.amount}</b>
                </Typography>

                <Typography variant="body1" gutterBottom>
                  Reference: <b>{senderDetailsBag.reference}</b>
                </Typography>

                <Button
                  className={classes.loginButton}
                  type="button"
                  variant="contained"
                  fullWidth
                  color="primary"
                  style={{
                    margin: "5px",
                    textTransaform: "none",
                    backgroundColor: "#155054",
                    color: "white",
                  }}
                  onClick={() => submitBulkPayment()}>
                  {" "}
                  Submit
                </Button>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={false} sm={2} md={3} lg={4} />
        </Grid>
      </Grid>
    </main>
  );
};

export default BusinessAccountDetails;
