import React, { useContext, useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { CartContext } from "../../Context/CartContext";

// import styles & iconography
import useStyles from "../Login/styles";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { CardHeader } from "@material-ui/core";

// import Axios client
import axios from "axios";

export const DepositStatus = () => {
  const classes = useStyles();
  const history = useHistory();

  const [open, setOpen] = useState(false);

  const { baseURL, loginDetailsBag, dataReceiver } = useContext(CartContext);
  const [loginDetails, setLoginDetails] = loginDetailsBag;
  const [paymentPlatforms, setPaymentPlatforms] = useState([]);
  const [selectedPaymentPlatform, setSelectedPaymentPlatform] = useState([]);
  const [reference, setReference] = useState("");
  const dataDec = dataReceiver;

  const loanStatusResponse = history.location.loanRequestState;
  const depositStatus = history.location.depositRequestState;

  console.log(depositStatus);

  const checkObjType = history.location.loginState;
  if (
    typeof checkObjType !== "undefined" &&
    Object.keys(history.location.loginState).length > 0
  ) {
    setLoginDetails(history.location.loginState);
    history.push({ pathname: "/", state: {} });
  }

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div className={classes.toolbar} />

      <Grid container direction="column">
        <Grid item></Grid>

        <Grid item container spacing={4}>
          <Grid item xs={false} sm={2} md={3} lg={4} />
          <Grid item xs={12} sm={8} md={6} lg={4}>
            {/*<Grid item xs={false} sm={4}/>
            
                        <Grid item xs={12} sm={4}>*/}
            <Card>
              <CardContent>
                <CardHeader title="Deposit Status" align="center" />

                <Typography variant="body1" gutterBottom>
                  Transaction ID: <b>{depositStatus.transactionID}</b> <br />
                  Membership Number: <b>
                    {depositStatus.membershipNumber}
                  </b>{" "}
                  <br />
                  Reference: <b>{depositStatus.reference}</b> <br />
                  Paid Amount: <b>E {depositStatus.amount}</b> <br />
                  Payment method: <b>{depositStatus.paymentMethod}</b> <br />
                </Typography>

                <Link to="/deposits">Close</Link>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={false} sm={2} md={3} lg={4} />
        </Grid>
      </Grid>
      <div className={classes.toolbar} />
    </main>
  );
};

export default DepositStatus;
