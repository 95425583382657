import React, { useContext } from "react";
import { Card, CardContent, Typography, Grid, Button } from "@material-ui/core";
import useStyles from "../Login/styles";
import { useHistory } from "react-router-dom";
import { CartContext } from "../../Context/CartContext";

export const BusinessAccountActivity = () => {
  const classes = useStyles();
  const history = useHistory();
  const {
    baseURL,
    loginDetailsBag,
    accountsTypeBag,
    legalBusinessMemberAccountsBag,
    userRoleStatusesBag,
  } = useContext(CartContext);
  const [loginDetails, setLoginDetails] = loginDetailsBag;
  const [legalBusinessMemberAccount, setLegalBusinessMemberAccount] =
    legalBusinessMemberAccountsBag;
  const [userRoleStatus, setUserRoleStatus] = userRoleStatusesBag;
  const [accountsType, setAccountsType] = accountsTypeBag;
  const checkObjType = history.location.loginState;
  let isBusinessAccount = "";
  let userAccountType = "";
  let accountUsersRole = "";

  if (
    typeof checkObjType !== "undefined" &&
    Object.keys(history.location.loginState).length > 0
  ) {
    setLoginDetails(checkObjType);
    let isBusinessAccount = checkObjType.isLegalBusiness;

    if (isBusinessAccount == "T") {
      userAccountType = "businessAccount";
      setAccountsType(userAccountType);
    } else if (isBusinessAccount == "F") {
      userAccountType = "personalAccount";
      setAccountsType(userAccountType);
    }
  }

  const localPayments = () =>
    history.push({
      pathname: "/activatebusiness",
    });

  const openBusiness = () =>
    history.push({
      pathname: "/activatebusiness",
    });

  const openTransactionList = () =>
    history.push({
      pathname: "/businesstransactions",
    });

  const activateBusiness = () =>
    history.push({
      pathname: "/activateepaynetbusiness",
    });

  const openHistory = () =>
    history.push({
      pathname: "/businessactivityhistory",
      loginState: loginDetails,
    });

  const ePayNetMembers = () =>
    history.push({
      pathname: "/businessmembers",
      loginState: loginDetails,
    });

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />

      <Grid container direction="column">
        <Grid
          item
          className={classes.sidebar}
          xs={false}
          sm={3}
          md={3}
          lg={3}></Grid>

        <Grid item container spacing={4}>
          <Grid item xs={12} sm={2} md={3} lg={4} />

          <Grid item xs={12} sm={8} md={6} lg={4}>
            <Card>
              <Grid container spacing={2}>
                <Grid item xs={12} align="center">
                  <Typography align="center" variant="body1">
                    <b>{loginDetails.fullName}</b>
                  </Typography>
                </Grid>
              </Grid>

              <hr></hr>
              <CardContent>
                <Grid item xs={12} align="center">
                  <Typography align="center" variant="body1">
                    <a
                      href="+baseURL+/ePayNetTemplates/ePayNetBusinessTemplate.csv"
                      target="_blank">
                      Click here
                    </a>{" "}
                    to download csv template file
                  </Typography>
                </Grid>
                <br />

                {userRoleStatus == "inputter" ? (
                  <>
                    <Button
                      className={classes.button}
                      type="button"
                      variant="contained"
                      fullWidth
                      color="primary"
                      style={{
                        margin: "5px",
                        textTransaform: "none",
                        backgroundColor: "#155054",
                        color: "white",
                      }}
                      onClick={() => openBusiness()}>
                      Update Proof Of Payment{" "}
                    </Button>

                    <Button
                      className={classes.button}
                      type="button"
                      variant="contained"
                      fullWidth
                      color="primary"
                      style={{
                        margin: "5px",
                        textTransaform: "none",
                        backgroundColor: "#155054",
                        color: "white",
                      }}
                      onClick={() => openHistory()}>
                      {" "}
                      Transactions History{" "}
                    </Button>

                    <Button
                      className={classes.button}
                      type="button"
                      variant="contained"
                      fullWidth
                      color="primary"
                      style={{
                        margin: "5px",
                        textTransaform: "none",
                        backgroundColor: "#155054",
                        color: "white",
                      }}
                      onClick={() => ePayNetMembers()}>
                      ePayNet Business Members{" "}
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      className={classes.button}
                      type="button"
                      variant="contained"
                      fullWidth
                      color="primary"
                      style={{
                        margin: "5px",
                        textTransaform: "none",
                        backgroundColor: "#155054",
                        color: "white",
                      }}
                      onClick={() => openHistory()}>
                      Authorize Transactions
                    </Button>

                    <Button
                      className={classes.button}
                      type="button"
                      variant="contained"
                      fullWidth
                      color="primary"
                      style={{
                        margin: "5px",
                        textTransaform: "none",
                        backgroundColor: "#155054",
                        color: "white",
                      }}>
                      {" "}
                      Transactions List{" "}
                    </Button>

                    <Button
                      className={classes.button}
                      type="button"
                      variant="contained"
                      fullWidth
                      color="primary"
                      style={{
                        margin: "5px",
                        textTransaform: "none",
                        backgroundColor: "#155054",
                        color: "white",
                      }}
                      onClick={() => ePayNetMembers()}>
                      ePayNet Business Members{" "}
                    </Button>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={false} xs={false} sm={2} md={3} lg={3} />
        </Grid>
      </Grid>
    </main>
  );
};

export default BusinessAccountActivity;
