import React, { useEffect, useContext } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  MenuItem,
} from "@material-ui/core";
import useStyles from "../Login/styles";
import { TextField } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { CartContext } from "../../Context/CartContext";
import axios from "axios";
import Backdrop from "@material-ui/core/Backdrop";
import Loader from "../Loader/Loader";

export const ConfirmService = () => {
  const classes = useStyles();
  const history = useHistory();
  const { loginDetailsBag, baseURL, dataReceiver } = useContext(CartContext);
  const [loginDetails, setLoginDetails] = loginDetailsBag;
  const [open, setOpen] = React.useState(false);
  const [paymentPlatforms, setPaymentPlatforms] = React.useState([]);
  const [responseStatus, setResponseStatus] = React.useState([]);
  const [selectedPaymentPlatform, setSelectedPaymentPlatform] = React.useState(
    []
  );
  let selectedPlatform = "";
  let companyDetails = "";
  let companyName = "";
  let data2 = {};
  let data = {};
  let responseDetailsBag = {};
  const dataDec = dataReceiver;

  const beneficiaryServiceDetailsBag = history.location.beneficiaryServiceState;

  useEffect(() => {
    setOpen(!open);
    const fetchPaymentPlatform = async () => {
      const res = await axios.get(baseURL + "bridgeBringPaymentPlatforms.php");

      res.data = dataDec(res.data);

      setPaymentPlatforms(res.data);
      setOpen(false);
    };

    fetchPaymentPlatform();
  }, []);

  const goToLenderConfirmationStatus = () => history.push({});

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedPaymentPlatform(event.target.value);
    selectedPlatform = event.target.value;

    return selectedPlatform;
  };

  const account = beneficiaryServiceDetailsBag.reference;

  const min = 100;
  const max = 999;
  const rand = Math.floor(Math.random() * (max - min + 1)) + min;
  const randAccountNumber = `${rand}${account}`;

  function d2h(d) {
    return d.toString(16);
  }
  function h2d(h) {
    return parseInt(h, 16);
  }
  function stringToHex(tmp) {
    let str = "",
      i = 0,
      tmp_len = tmp.length,
      c;

    for (; i < tmp_len; i += 1) {
      c = tmp.charCodeAt(i);
      str += d2h(c) + "";
    }
    return str;
  }

  const rand3hexAccountNumber = stringToHex(randAccountNumber);

  const submitPayment = () => {
    setOpen(!open);
    let dt = new Date();
    let seconds = dt.getSeconds();
    let minutes = dt.getMinutes();
    let categoryID = beneficiaryServiceDetailsBag.reference;
    let branchesID = beneficiaryServiceDetailsBag.reference;
    let organisation = beneficiaryServiceDetailsBag.reference;
    let accountNumber = rand3hexAccountNumber;
    let amount = beneficiaryServiceDetailsBag.amount;
    let nationalID = beneficiaryServiceDetailsBag.reference;
    let reference = beneficiaryServiceDetailsBag.reference;
    let companyName = beneficiaryServiceDetailsBag.reference;
    let token =
      minutes + "" + beneficiaryServiceDetailsBag.reference + "" + seconds;
    let appUser = beneficiaryServiceDetailsBag.userKey;
    let paymentPlatform = selectedPaymentPlatform;
    let username = loginDetails.username;

    data2 = {
      categoryID: categoryID,
      organisation: organisation,
      branchCode: branchesID,
      accountNumber: accountNumber,
      amount,
      reference,
      nationalID,
      companyID: appUser,
      paymentPlatform,
      username,
      destination_platform_id: 5,
      token,
    };

    data = JSON.stringify(data2);

    const res = axios
      .post(baseURL + "bridgeReceiveMobileAppPayment.php", data)
      .then(function (response) {
        if (response.data !== "") {
          responseDetailsBag = response.data;
          goToPaymentStatus();
          setOpen(false);
        } else {
          alert("Connection error, Please try again later.");
          setOpen(false);
        }
      });
  };

  const goToPaymentStatus = () =>
    history.push({
      //let responseDetailsBag = bankTransactionDetailsBag;
      pathname: "/localtransactionstatus",
      responseState: responseDetailsBag,
    });

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <Grid container direction="column">
        <Grid item></Grid>

        <Grid item container spacing={4}>
          {/*<Grid item xs={false} sm={4}/>
            
                        <Grid item xs={12} sm={4}>*/}
          <Grid item xs={false} sm={2} md={3} lg={4} />
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <Card>
              <Backdrop className={classes.backdrop} open={open}>
                <Loader />
              </Backdrop>

              <CardContent>
                <Typography align="center" variant="body1">
                  <h5>Confirm Transaction</h5>
                </Typography>

                <Typography variant="body1" gutterBottom>
                  Daily Available Limit: <b>E{loginDetails.available}</b>
                </Typography>

                <Typography variant="body1" gutterBottom>
                  Monthly Available Limit:{" "}
                  <b>E{loginDetails.monthlyAvailableLimit}</b>
                </Typography>

                <Typography variant="body1" gutterBottom>
                  Recipient :
                  <b>{beneficiaryServiceDetailsBag.accountNumber} </b>
                </Typography>

                <Typography variant="body1" gutterBottom>
                  Reference:<b>{beneficiaryServiceDetailsBag.reference} </b>
                </Typography>

                <Typography variant="body1" gutterBottom>
                  Total Balance:<b>E{beneficiaryServiceDetailsBag.amount} </b>
                </Typography>

                <Typography variant="body1" gutterBottom>
                  <TextField
                    label="Payment Platform"
                    variant="outlined"
                    fullWidth
                    size="small"
                    select
                    onChange={handleChange}
                    value={selectedPaymentPlatform}>
                    {paymentPlatforms.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.description}
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>

                <Button
                  className={classes.loginButton}
                  type="button"
                  variant="contained"
                  fullWidth
                  color="primary"
                  style={{
                    margin: "5px",
                    textTransaform: "none",
                    backgroundColor: "#155054",
                    color: "white",
                  }}
                  onClick={() => submitPayment()}>
                  {" "}
                  Submit
                </Button>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={false} sm={2} md={3} lg={4} />
        </Grid>
      </Grid>
    </main>
  );
};

export default ConfirmService;
